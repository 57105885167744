// @format

export function setDisallowedWarnListener(element): void {
  const jqElement = $(element)
  const disallowedRegions = jqElement.data('disallowed');
  showMsg(jqElement, disallowedRegions);
  highlightWarnItems(jqElement, disallowedRegions);
  jqElement.on('change', () => {
    showMsg(jqElement, disallowedRegions);
    highlightWarnItems(jqElement, disallowedRegions);
  })
}

function showMsg(element, disallowedRegions) {
  const inputValue = element.val().split(',');
  const disallowedRegionsInVal = new Set;
  const msgWrap = $('#disallowed-msg-wrap');
  const msgBlock = msgWrap.find('.msg')
  inputValue.forEach(function (region) {
    const regionLowCase = region.toLowerCase()
    if (disallowedRegions.includes(regionLowCase)) {
      disallowedRegionsInVal.add(`${regionLowCase[0].toUpperCase()}${regionLowCase.slice(1)}`)
    }
  });
  if (disallowedRegionsInVal.size > 0) {
    const targetingLocation = element.closest('div.targeting_location')
    const msg = `Disallowed region(s): ${Array.from(disallowedRegionsInVal).join(', ')}. <a href="https://qualifierai.tawk.help/article/does-qualifier-work-in-germany" target="_blank">Read more.</a>`
    if (msgBlock.text().length > 0) {
      msgBlock.html(msg);
    } else {
      $(disallowedNotice(msg)).insertAfter(targetingLocation.find('.choices'))
    }
  } else {
    msgWrap.remove();
  }
}

function highlightWarnItems(element, disallowedRegions) {
  element.closest('.choices__inner').find('.choices__list .choices__item')
    .each((_i, item) => {
      if (disallowedRegions.includes($(item).data('value').toLowerCase())) {
        $(item).addClass('disallowed-item');
      }
    })
}

function disallowedNotice(msg) {
  return ['<div class="flow-info-box alert" id="disallowed-msg-wrap">',
    '<span class="msg">',
    msg,
    '</span>',
    '</div>'].join('')
}
